(function($) {
  var hpDonate = document.querySelector("#form-homepage-donate");
  var hpDonateAmount = hpDonate.querySelector("#donate-amount");
  hpDonate.addEventListener("submit", function(e) {
    e.preventDefault();
    window.open(
      `https://donatetoriseboro.funraise.org/?x_amount=${
        hpDonateAmount.value
      }&x_frequency=o`
    );
  });
})(jQuery);
